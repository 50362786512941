import acceptInvite from './es_MX/accept-invite.json';
import apiKeys from './es_MX/api-keys.json';
import audioLibrary from './es_MX/audio-library.json';
import auth from './es_MX/auth.json';
import autoReceptionists from './es_MX/auto-receptionists.json';
import autoUpdater from './es_MX/auto-updater.json';
import billing from './es_MX/billing.json';
import callQueues from './es_MX/call-queues.json';
import calls from './es_MX/calls.json';
import common from './es_MX/common.json';
import company from './es_MX/company.json';
import contacts from './es_MX/contacts.json';
import countries from './es_MX/countries.json';
import enums from './es_MX/enums.json';
import errors from './es_MX/errors.json';
import general from './es_MX/general.json';
import messages from './es_MX/messages.json';
import navigation from './es_MX/navigation.json';
import onboarding from './es_MX/onboarding.json';
import phoneNumbers from './es_MX/phone-numbers.json';
import preferences from './es_MX/preferences.json';
import profile from './es_MX/profile.json';
import security from './es_MX/security.json';
import settings from './es_MX/settings.json';
import subscriptionsWarning from './es_MX/subscriptions-warning.json';
import subscriptions from './es_MX/subscriptions.json';
import trustRegistration from './es_MX/trust-registration.json';
import trust from './es_MX/trust.json';
import users from './es_MX/users.json';
import voicemails from './es_MX/voicemails.json';
import zod from './es_MX/zod.json';

export default {
  'accept-invite': acceptInvite,
  'api-keys': apiKeys,
  'audio-library': audioLibrary,
  auth: auth,
  'auto-receptionists': autoReceptionists,
  'auto-updater': autoUpdater,
  billing: billing,
  'call-queues': callQueues,
  calls: calls,
  common: common,
  company: company,
  contacts: contacts,
  countries: countries,
  enums: enums,
  errors: errors,
  general: general,
  messages: messages,
  navigation: navigation,
  onboarding: onboarding,
  'phone-numbers': phoneNumbers,
  preferences: preferences,
  profile: profile,
  security: security,
  settings: settings,
  'subscriptions-warning': subscriptionsWarning,
  subscriptions: subscriptions,
  trust: trust,
  'trust-registration': trustRegistration,
  users: users,
  voicemails: voicemails,
  zod
};
