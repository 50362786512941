import { SSRProvider } from '@diallink-corp/convergo-aria-ssr';
import { RemixBrowser, useLocation, useMatches } from '@remix-run/react';
import * as Sentry from '@sentry/remix';
import { browserTracingIntegration, replayIntegration } from '@sentry/remix';
import i18next from 'i18next';
import I18nextBrowserLanguageDetector from 'i18next-browser-languagedetector';
import { startTransition, useEffect } from 'react';
import { hydrateRoot } from 'react-dom/client';
import { I18nextProvider, initReactI18next } from 'react-i18next';
import { getInitialNamespaces } from 'remix-i18next/client';
import { registerSW } from 'virtual:pwa-register';
import { z } from 'zod';

import * as i18n from '~/lib/i18n.config';

import { createErrorMap } from './lib/zod.config';

if (import.meta.env.PROD) {
  Sentry.init({
    dsn: 'https://f7711fd34a5a5c26ed98a41ccabb1e09@o426295.ingest.us.sentry.io/4507787098193920',
    tracesSampleRate: 1,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1,
    environment: import.meta.env.VITE_APP_ENV,
    integrations: [
      browserTracingIntegration({
        useEffect,
        useLocation,
        useMatches
      }),
      replayIntegration()
    ],
    ignoreErrors: [
      'signal is aborted without reason',
      /^Expected fetch controller:.*$/
    ]
  });
}

// For the reference @see https://developer.chrome.com/blog/using-requestidlecallback
window.requestIdleCallback =
  window.requestIdleCallback ||
  function (cb) {
    const start = Date.now();
    return setTimeout(function () {
      cb({
        didTimeout: false,
        timeRemaining: function () {
          return Math.max(0, 50 - (Date.now() - start));
        }
      });
    }, 1);
  };

window.cancelIdleCallback =
  window.cancelIdleCallback ||
  function (id) {
    clearTimeout(id);
  };

const intervalMS = 60 * 60 * 1000;

registerSW({
  immediate: true,
  onRegisteredSW(swScriptUrl: string, registration) {
    registration &&
      setInterval(async () => {
        if (registration.installing || !navigator) return;

        if ('connection' in navigator && !navigator.onLine) return;

        const resp = await fetch(swScriptUrl, {
          cache: 'no-store',
          headers: {
            cache: 'no-store',
            'cache-control': 'no-cache'
          }
        });

        if (resp?.status === 200) await registration.update();
      }, intervalMS);
  },
  onOfflineReady() {
    console.log('DialLink is ready to work offline');
  }
});

async function main() {
  // eslint-disable-next-line import/no-named-as-default-member
  await i18next
    .use(initReactI18next)
    .use(I18nextBrowserLanguageDetector)
    .init({
      ...i18n,
      ns: getInitialNamespaces(),
      detection: {
        order: ['cookie', 'htmlTag'],
        caches: [],
        lookupCookie: 'lng'
      }
    });

  // eslint-disable-next-line import/no-named-as-default-member
  z.setErrorMap(createErrorMap(i18next.t, 'zod'));

  startTransition(() => {
    hydrateRoot(
      document,
      <I18nextProvider i18n={i18next}>
        <SSRProvider>
          <RemixBrowser />
        </SSRProvider>
      </I18nextProvider>
    );
  });
}

main().catch((error) => console.error(error));
