import { TFunction } from 'i18next';
import { z, ZodIssueCode } from 'zod';

/**
 * List of types that have plurals in error messages.
 * */
const pluralTypes = ['string', 'set', 'array'];

/**
 * List of types that required a valid type.
 * */
const requiredValidTypes = ['string', 'set', 'array', 'date', 'number'];

export function createErrorMap(t: TFunction, ns: string): z.ZodErrorMap {
  /**
   * To handle other scenarios, add a logic check into the existing ZodIssueCode
   * or introduce a new ZodIssueCode if it is not already available.
   *
   * References:
   * - Customizing errors with ZodErrorMap:
   *   https://zod.dev/ERROR_HANDLING?id=customizing-errors-with-zoderrormap
   * - ZodIssueCode:
   *   https://zod.dev/ERROR_HANDLING?id=zodissuecode
   */
  return (issue) => {
    let zodIssue = { message: t('default-error', { ns }) };

    /**
     * Handle custom messages for IVR menu of Auto-receptionists,
     * as Zod currently does not directly support custom error messages
     * for 'discriminatedUnion' objects within a 'discriminatedUnion' object.
     * */
    if (issue.path.includes('ivrMenu')) {
      if (issue.path.includes('actions') && issue.path.length <= 3) {
        zodIssue = { message: t('custom.required-ivr-key', { ns }) };
      } else {
        zodIssue = { message: t('custom.required-selection', { ns }) };
      }
      return zodIssue;
    }

    switch (issue.code) {
      /**
       * Handle cases where a valid type is required:
       * 'string', 'set', 'array', 'date', 'number'.
       */
      case ZodIssueCode.invalid_type: {
        const expectedType = issue.expected;
        if (requiredValidTypes.includes(expectedType)) {
          zodIssue = { message: t(`required.${expectedType}`, { ns }) };
        }
        break;
      }
      /**
       * Handle cases where email/URL format is required.
       * */
      case ZodIssueCode.invalid_string:
        if (issue.validation === 'email') {
          zodIssue = { message: t(`required.email`, { ns }) };
        } else if (issue.validation === 'url') {
          zodIssue = { message: t(`required.url`, { ns }) };
        }
        break;

      /**
       * Handle the case where a minimum number is required.
       * */
      case ZodIssueCode.too_small:
        zodIssue =
          pluralTypes.includes(issue.type) && typeof issue.minimum === 'number'
            ? {
                message: t(`min.${issue.type}.count`, {
                  count: issue.minimum,
                  ns
                })
              }
            : { message: t(`min.${issue.type}`, { min: issue.minimum, ns }) };
        break;

      /**
       * Handle the case where a maximum number is required.
       * */
      case ZodIssueCode.too_big:
        zodIssue =
          pluralTypes.includes(issue.type) && typeof issue.maximum === 'number'
            ? {
                message: t(`max.${issue.type}.count`, {
                  count: issue.maximum,
                  ns
                })
              }
            : { message: t(`max.${issue.type}`, { max: issue.maximum, ns }) };
        break;

      /**
       * Handle the case where a custom message is used..
       * */
      case ZodIssueCode.custom: {
        const { key, values } = getKeyAndValues(
          issue.params?.i18n,
          'default-error'
        );

        return {
          message: t(key, {
            ...values,
            ns,
            defaultValue: issue.message,
            ...issue.path
          })
        };

        break;
      }
    }

    return zodIssue;
  };
}

const getKeyAndValues = (
  param: unknown,
  defaultKey: string
): {
  values: Record<string, unknown>;
  key: string;
} => {
  if (typeof param === 'string') return { key: param, values: {} };

  if (isRecord(param)) {
    const key =
      'key' in param && typeof param.key === 'string' ? param.key : defaultKey;
    const values =
      'values' in param && isRecord(param.values) ? param.values : {};
    return { key, values };
  }

  return { key: defaultKey, values: {} };
};

const isRecord = (value: unknown): value is Record<string, unknown> => {
  if (typeof value !== 'object' || value === null) return false;

  for (const key in value) {
    if (!Object.prototype.hasOwnProperty.call(value, key)) return false;
  }

  return true;
};
