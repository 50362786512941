import deDE from '~/locales/de_DE';
import enUS from '~/locales/en_US';
import esMX from '~/locales/es_MX';

// This is the list of languages your application supports,
// the fallback is always the last
export const supportedLngs = ['de-DE', 'en-US', 'es-MX'];

// This is the language you want to use in case
// if the user preferred language is not in the supportedLngs
export const fallbackLng = 'en-US';

// The default namespace of i18next is "translation", but you can customize it
// here
export const defaultNS = 'common';

// These are the translation files we created, `translation` is the namespace
// we want to use, we'll use this to include the translations in the bundle
// instead of loading them on-demand
export const resources = {
  'de-DE': deDE,
  'en-US': enUS,
  'es-MX': esMX
};
